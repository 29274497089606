import { Link } from "gatsby";
import * as React from "react";
import Seo from "../components/global/seo";
import "../components/global/styles.scss";

const NotFoundPage = () => {
  return (
    <main className="container404">
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
      <Link to="/">
        <p>Come back to home</p>
      </Link>
    </main>
  );
}

export default NotFoundPage;
